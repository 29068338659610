import configuration from "../config/Configuration";

export default function useRocketEnabled() {
  if (!configuration.isRocketEnabled) return false;
  // For ease of testing, use ?debug_use_rocket=false to override the feature toggle
  // In PMSPA this will NOT override the background color which will remain as default for the environment
  const params = new URLSearchParams(window.location.search);
  if (params.get("debug_use_rocket") === "false") return false;
  return true;
}
