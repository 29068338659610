import { getMetaTagContent } from "./ConfigurationHelpers";

export interface Configuration {
  serverUrl: string;
  flybuysLogoutUrl: string;
  isDevelopmentToolsEnabled: boolean;
  allowedReturnUrls: string[];
  enablePostcodeFeature: boolean;
  securityPreferenceCustomerType: boolean;
  marketingPreferencePushFeature: boolean;
  marketingPreferencePaidFeature: boolean;
  sentryEnv?: string;
  isRocketEnabled: boolean;
}

let configuration: Configuration;
if (process.env.NODE_ENV !== "production") {
  configuration = {
    serverUrl: "http://localhost:5006",
    flybuysLogoutUrl:
      "https://auth1.flybuys-dev.com.au/v2/logout?returnTo=http%3A%2F%2Fcoles.com.au&client_id=partner-coles-app-dev",
    isDevelopmentToolsEnabled: true,
    allowedReturnUrls: ["https://google.com"],
    enablePostcodeFeature: true,
    securityPreferenceCustomerType: true,
    marketingPreferencePushFeature: true,
    marketingPreferencePaidFeature: true,
    isRocketEnabled: true,
  };
} else {
  configuration = {
    serverUrl: getMetaTagContent("server-url"),
    flybuysLogoutUrl: getMetaTagContent("flybuys-logout-url"),
    isDevelopmentToolsEnabled: false,
    allowedReturnUrls: parseReturnUrls("allowed-return-urls"),
    enablePostcodeFeature: isFeatureEnabled("postcode-feature-toggle"),
    securityPreferenceCustomerType: isFeatureEnabled(
      "security-preference-customer-type-feature-toggle"
    ),
    marketingPreferencePushFeature: isFeatureEnabled(
      "marketing-preference-push-feature-toggle"
    ),
    marketingPreferencePaidFeature: isFeatureEnabled(
      "marketing-preference-paid-feature-toggle"
    ),
    sentryEnv: getMetaTagContent("sentry-env"),
    isRocketEnabled: isFeatureEnabled("use-rocket-enabled-feature-toggle"),
  };
}

function isFeatureEnabled(metaProperty: string): boolean {
  return getMetaTagContent(metaProperty) === "true";
}

export function parseReturnUrls(metaProperty: string): string[] {
  try {
    const returnUrls = getMetaTagContent(metaProperty)?.trim();
    return returnUrls ? returnUrls.split(",") : [];
  } catch {
    return [];
  }
}

export default configuration;
