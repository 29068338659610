import { CCPTheme, ColesClientName } from "ccp-common-ui-components";
import useRocketEnabled from "../hooks/useRocketEnabled";

function RocketAwareThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <CCPTheme
      clientName={ColesClientName.ColesApp}
      withRocket={useRocketEnabled()}
    >
      {children}
    </CCPTheme>
  );
}

export { RocketAwareThemeProvider };
